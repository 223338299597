<template>

  <XModal ref="XModal" :show-overlay="true">

    <template #header>
      Выбор процесса
    </template>

    <div :style="{minWidth: '50vw', maxWidth: '80vw'}">

      <TablePage ref="TablePage" :preserve-load-table-items-on-create="true" main-url="/constructor/selectProcess"
                 @tableItemsClick="tableItemsClick">

        <template #tableColumns="TableProps">

          <Column :sortable="true" field="process_view" filter-field="process_view" style="min-width: 240px;">
            <template #header>
              <SearchInput v-model="TableProps.filters['process_view']" label="Название процесса"/>
            </template>
          </Column>

          <Column :sortable="true" field="_passed_docs$reg_date"
                  style="min-width: 200px;">
            <template #header>
              <SearchDate v-model="TableProps.filters['reg_date']" label="Рег.дата"/>
            </template>
            <template #body="slotProps">
                            <span>{{
                                this.$refs.TablePage.dateToDMY(slotProps.data?.['_passed_docs$reg_date'] * 1000)
                              }}</span>
            </template>
          </Column>

          <Column :sortable="true" field="_passed_docs$reg_num">
            <template #header>
              <SearchInput v-model="TableProps.filters['reg_num']" label="Рег.номер"
                           style="min-width: 150px;"/>
            </template>
            <template #body="slotProps">
                            <span v-if="slotProps.data?.['_passed_docs$reg_num']">{{
                                slotProps.data?.['_passed_docs$reg_num']
                              }}</span>
            </template>
          </Column>

          <Column :sortable="true" field="process_type_name" filter-field="process_type_name"
                  style="min-width: 200px;">
            <template #header>
              <SearchInput v-model="TableProps.filters['process_type_name']" label="Вид процесса"/>
            </template>
          </Column>

          <Column :sortable="true" field="creator_title" filter-field="creator_title"
                  style="min-width: 240px;">
            <template #header>
              <SearchInput v-model="TableProps.filters['creator_title']" label="Создатель процесса"/>
            </template>
          </Column>

          <Column :sortable="true" field="guid" filter-field="guid" style="min-width: 200px;">
            <template #header>
              <SearchInput v-model="TableProps.filters['guid']" label="ИД процесса"/>
            </template>
          </Column>

          <Column v-if="show_finishedColumn_prop || showFinishedColumn" field="is_finished"
                  filter-field="is_finished">
            <template #header>
              Процесс завершен &nbsp;
              <TriStateCheckbox v-model="TableProps.filters['is_finished']"/>
            </template>
            <template #body="columnProps">
              <div v-if="columnProps.data.is_finished">Да</div>
              <div v-else>нет</div>
            </template>
          </Column>

        </template>

      </TablePage>

    </div>

  </XModal>

  <ProcessSelectorCard ref="ProcessSelectorCard" @selectClicked="selectClicked"/>

</template>

<script>

import {SearchDate, SearchInput, TablePage, XModal} from '@xnpmpackages/xcomponents';
import ProcessSelectorCard from "@/components/Constructor/ProcessSelector/ProcessSelectorCard";

export default {
  name: "ProcessSelector",
  props: {
    show_finishedColumn_prop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      promiseResolver: null,
      showFinishedColumn: false,
    }
  },
  methods: {

    async selectClicked($processAndEvents) {
      await this.$refs.XModal.hide();
      this.promiseResolver($processAndEvents);
    },

    tableItemsClick($event) {
      this.$refs.ProcessSelectorCard.show($event.data);
    },

    async select($options) {

      if ($options?.selectFirstIfOnlyOne) {

        const filters = {...$options.filters, ...$options.custom_filters}

        const response1 = await this.$xapi.post('/constructor/selectProcess',
          {
            action: 'gettableItems',
            filter: filters ?? {},
            paginator: {page: 1, limit: 20},
          });

        if (response1.data.rows_count === 1) {

          const process = response1.data.tableItems[0]

          let parentProcess = null
          if (process?.parentProcessGuid) {

            const response2 = await this.$xapi.post(
              '/constructor',
              {
                action: 'getProcessInfo',
                guid: process?.parentProcessGuid,

              },
            );

            parentProcess = response2.data.processInfo;

          }

          return {
            process: process,
            events: null,
            parentProcess: parentProcess
          }

        } else {
          return null;
        }


      } else {

        this.$refs.XModal.show().then(() => {

          this.showFinishedColumn = $options?.showFinishedColumn;
          this.$refs.TablePage.setCustomFilters($options?.custom_filters);
          this.$refs.TablePage.filters = $options?.filters ?? {};
          //this.$refs.TablePage.getTableRows(true);

        });

        return new Promise(resolve => this.promiseResolver = resolve);

      }

    }


  },

  components: {
    ProcessSelectorCard,
    XModal,
    TablePage,
    SearchInput,
    SearchDate
  }
}
</script>

<style scoped>

</style>