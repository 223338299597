

import {DataFilters, SearchDate, SearchInput, TablePage, XModal} from "@xnpmpackages/xcomponents/dist/index"

export default {
    name: "DocumentSelector",
    mixins: [DataFilters],
    data() {
        return {
            promiseResolver: null
        }
    },
    methods: {

        async tableItemsClick($event) {

            if (this.promiseResolver)
                this.promiseResolver($event.data);

            this.$refs.XModal.hide();

        },

        async select(filter, custom_filter) {

            let P = new Promise(resolve => this.promiseResolver = resolve);

            await this.$refs.XModal.show();

            if (custom_filter)
                this.$refs.TablePage.setCustomFilters(custom_filter);

            if (filter) {
                this.$refs.TablePage.filters = filter;
            } else {
                this.$refs.TablePage.getTableRows(true)
            }

            return P;

        }

    },
    components: {
        XModal,
        TablePage,
        SearchInput,
        SearchDate,
    }
}
