<template>

    <ReferenceSelector ref="ReferenceSelector"/>
    <AbonentSelector ref="AbonentSelector"/>
    <ProcessSelector ref="ProcessSelector"/>
    <DocumentSelector ref="DocumentSelector"/>
    <UserSelector ref="UserSelector"/>

</template>

<script>

import {DataFilters} from "@xnpmpackages/xcomponents/dist";
import $ from 'jquery';
import ReferenceSelector from "@/components/Constructor/ReferenceSelector";
import AbonentSelector from "@/components/Constructor/AbonentSelector";
import ProcessSelector from "@/components/Constructor/ProcessSelector";
import DocumentSelector from "@/components/Constructor/DocumentSelector";
import UserSelector from "@/components/Constructor/UserSelector";


export default {
    name: "AddButtons",
    components: {UserSelector, DocumentSelector, ProcessSelector, AbonentSelector, ReferenceSelector},
    props: {
        sourceOrgGuid: null,
        sourceOrgTitle: null,
    },
    methods: {

        async addButtons($iframe, $context) {

            function AddButtonsSelectAgent() {

                let $agentsLinks = $iframe.contentDocument.body.querySelectorAll('[data-xsd2html2xml-namewithoutns="ПредметыСобытия"] [data-xsd2html2xml-element-type-without-ns="ТипСсылкаАгента"]');

                $agentsLinks.forEach($agentLink => {

                    $($agentLink).find('[data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first').prop('disabled', true).addClass('disabled');
                    $($agentLink).find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first').prop('disabled', true).addClass('disabled');

                    $($agentLink).each(($index, item) => {

                        $(item).find('[data-xsd2html2xml-namewithoutns="АгентУУИД"]').addClass('serviceInfo')
                        $(item).find('[data-xsd2html2xml-namewithoutns="Представление"]').addClass('serviceInfo')

                        $(item).addClass('xButtonAdded');

                        $(item).children('.selectAgentBtn').remove();

                        let elemDiv = document.createElement('button');
                        elemDiv.innerText = 'Выбрать участника';
                        elemDiv.classList.value = 'p-button p-button-danger p-button-sm p-button-outlined p-component selectAgentBtn';
                        item.append(elemDiv);
                        $(elemDiv).css('margin-bottom', '5px');
                        $(elemDiv).css('margin-top', '5px');

                        // Проверка на то, что поля абонента заполнены. Если заполнены, то меняем стиль кнопки выбора абонента и текст кнопки
                        let v1 = $(item).find('[data-xsd2html2xml-namewithoutns="Представление"]').find('input, select, textarea').val()
                        if (v1) {
                            $(elemDiv).text(v1);
                            $(elemDiv).addClass('p-button-info').removeClass('p-button-danger').removeAttr('disabled')
                        }

                        $($iframe.contentDocument.body).on('click', '.selectAgentBtn', async ($event) => {

                            $event.stopImmediatePropagation();
                            $event.preventDefault();
                            $event.stopPropagation();

                            let result = await this.$refs.AbonentSelector.select();

                            if (result) {
                                $($event.target).addClass('p-button-info').removeClass('p-button-danger');
                                $($event.target).parent().data('abonent', result).addClass('xData');
                            } else {
                                $($event.target).addClass('p-button-danger').removeClass('p-button-info');
                            }

                            let $agent_guid = $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first')[0];
                            $agent_guid.value = result.guid;
                            $agent_guid.disabled = true;

                            let $agent_title = $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first')[0];
                            $agent_title.value = result.title;
                            $agent_title.disabled = true;

                            $($event.target).text(result.title);

                        });


                    });

                });

            }

            function AddButtonsSelectAgentsReceivers() {

                let $receivers = $iframe.contentDocument.body.querySelectorAll('[data-xsd2html2xml-namewithoutns="АгентыПолучатели"]');

                $receivers.forEach($receiver => {

                    let _eventAgents = $($receiver).parents('[data-xsd2html2xml-namewithoutns="АгентыСобытия"]:first');
                    let _eventWrapper = $(_eventAgents).parents('fieldset:first');
                    let _eventInfo = $(_eventWrapper).find('[data-xsd2html2xml-namewithoutns="ИнформацияОСобытии"]:first');
                    let $eventStatus = $(_eventInfo).find('[data-xsd2html2xml-namewithoutns="СтатусСобытия"]:first select:first').val();
                    let $eventProcessGuid = $(_eventWrapper).find('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"] [data-xsd2html2xml-namewithoutns="СсылкаПроцесса"] [data-xsd2html2xml-namewithoutns="ПроцессУУИД"] input:first').val();

                    $($receiver).find('[data-xsd2html2xml-namewithoutns="ВидИД"]').each((index, item) => $(item).addClass('serviceInfo'));
                    $($receiver).find('[data-xsd2html2xml-namewithoutns="ВидНазвание"]').each((index, item) => $(item).addClass('serviceInfo'));

                    $($receiver).find('[data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first').prop('disabled', true).addClass('disabled');
                    $($receiver).find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first').prop('disabled', true).addClass('disabled');

                    $($receiver).find('[data-xsd2html2xml-namewithoutns="СсылкаАгента"]').each(($index, item) => {

                        //console.log($(item).parent().children('.selectAbonentBtn')[0]);
                        $(item).parent().children('.selectAbonentBtn').remove()

                        $(item).addClass('serviceInfo');
                        $(item).addClass('xButtonAdded');

                        let elemDiv = document.createElement('button');
                        elemDiv.innerText = 'Выбрать участника';
                        elemDiv.classList.value = 'p-button p-button-danger p-button-sm p-button-outlined p-component selectAbonentBtn';
                        item.before(elemDiv);
                        $(elemDiv).css('margin-bottom', '5px');

                        if ($eventStatus !== 'Инициация' && !$eventProcessGuid) {
                            $(elemDiv).prop('disabled', true).addClass('disabled');
                        }

                        // Проверка на то, что поля абонента заполнены. Если заполнены, то меняем стиль кнопки выбора абонента и текст кнопки
                        let v1 = $(item).find('[data-xsd2html2xml-namewithoutns="Представление"]').find('input, select, textarea').val()
                        if (v1) {
                            $(elemDiv).text(v1);
                            $(elemDiv).addClass('p-button-info').removeClass('p-button-danger').removeAttr('disabled')
                        }

                        $($iframe.contentDocument.body).on('click', '.selectAbonentBtn', async ($event) => {

                            $event.stopImmediatePropagation();
                            $event.preventDefault();
                            $event.stopPropagation();

                            let _eventAgents = $($event.target).parents('[data-xsd2html2xml-namewithoutns="АгентыСобытия"]:first');

                            let $processData = $(_eventAgents).parents('fieldset:first').find('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"]:first').data('process');

                            let $allowed_guids = $processData?.roles?.map(item => {
                                return item.agentGuid;
                            });

                            /*console.log($allowed_guids);

                            if ($allowed_guids && $context?.destinations) {
                                $allowed_guids = [...$allowed_guids, ...$context?.destinations]
                            } else {
                                $allowed_guids = $context?.destinations
                            }*/

                            const event_status = $($event.target).parents('.eventBlock').find('section > [data-xsd2html2xml-namewithoutns="ИнформацияОСобытии"] > section > [data-xsd2html2xml-namewithoutns="СтатусСобытия"] > select').val()

                            let abonent_filter = {}

                            // Если событие не инициирующее, то добавляем к фильтру селектора абонентов роль абонента в процессе и GUID процесса
                            if (event_status && event_status !== 'Инициация') {

                                const $abonent_role = $($event.target).parent().siblings('[data-xsd2html2xml-namewithoutns="ВидИД"]').find('input').val();
                                const $process_guid = $($event.target).parents('.eventBlock').find('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"] > section > [data-xsd2html2xml-namewithoutns="СсылкаПроцесса"] > label[data-xsd2html2xml-namewithoutns="ПроцессУУИД"] > input').val()

                                abonent_filter = {role_id: $abonent_role, process_guid: $process_guid};
                            }


                            let result = await this.$refs.AbonentSelector.select({custom_filters: {guid: $allowed_guids, ...abonent_filter}});

                            if (result) {
                                $($event.target).addClass('p-button-info').removeClass('p-button-danger');
                                $($event.target).parent().data('abonent', result).addClass('xData');
                            } else {
                                $($event.target).addClass('p-button-danger').removeClass('p-button-info');
                            }

                            let $agent_guid = $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first')[0];
                            $agent_guid.value = result.guid;
                            $agent_guid.disabled = true;

                            let $agent_title = $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first')[0];
                            $agent_title.value = result.title;
                            $agent_title.disabled = true;

                            $($event.target).text(result.title);

                        });


                    });

                });
            }

            function AddButtonsSelectReference() {

                let $_references = $iframe.contentDocument.body.querySelectorAll('[data-xsd2html2xml-element-type-without-ns="ТипСсылкаСправочника"]');

                $($_references).not('[hidden]').each((idx, $_reference) => {


                    if (
                        !$($_reference).find('[data-xsd2html2xml-namewithoutns="ИД"]:first input:first').length ||
                        !$($_reference).find('[data-xsd2html2xml-namewithoutns="Название"]:first input:first').length ||
                        !$($_reference).parent().siblings('[data-xsd2html2xml-namewithoutns="ВидИД"]').length ||
                        !$($_reference).parent().siblings('[data-xsd2html2xml-namewithoutns="ВидНазвание"]').length
                    ) {
                        return;
                    }


                    $($_reference).addClass('serviceInfo');
                    $($_reference).find('[data-xsd2html2xml-namewithoutns="ИД"]:first input:first').prop('disabled', true).addClass('disabled');
                    $($_reference).find('[data-xsd2html2xml-namewithoutns="Название"]:first input:first').prop('disabled', true).addClass('disabled');
                    $($_reference).parent().siblings('[data-xsd2html2xml-namewithoutns="ВидИД"]').prop('disabled', true).addClass('disabled').addClass('serviceInfo');
                    $($_reference).parent().siblings('[data-xsd2html2xml-namewithoutns="ВидНазвание"]').prop('disabled', true).addClass('disabled').addClass('serviceInfo');

                    // Удаление кнопки если она уже есть. Для того, что бы если поля заполнены, то кнопка создалась заполненной тоже
                    $($_reference).parent().parent().children('.selectFromReference').remove()

                    // Создание самой кнопки
                    let elemDiv = document.createElement('button');
                    elemDiv.innerText = 'Выбрать из справочника';
                    elemDiv.classList.value = 'p-button p-button-danger p-button-sm p-button-outlined p-component selectFromReference mt-3 mb-3';
                    $(elemDiv).css('margin-top', '5px');

                    $($_reference).parent().parent().append(elemDiv);

                    // Проверка на то, что поля абонента заполнены. Если заполнены, то меняем стиль кнопки выбора абонента и текст кнопки
                    let v1 = $($_reference).find('[data-xsd2html2xml-namewithoutns="ИД"]').find('input, select, textarea').val()
                    let v2 = $($_reference).find('[data-xsd2html2xml-namewithoutns="Название"]').find('input, select, textarea').val()
                    if (v1 && v2) {
                        $(elemDiv).text(v2 ?? v1);
                        $(elemDiv).addClass('p-button-info').removeClass('p-button-danger').removeAttr('disabled')
                    }

                    $($iframe.contentDocument.body).on('click', '.selectFromReference', async ($event) => {

                        $event.stopImmediatePropagation();
                        $event.preventDefault();
                        $event.stopPropagation();

                        let $refId = $($event.target).siblings('[data-xsd2html2xml-namewithoutns="ВидИД"]').find('input:first').val();

                        let $refData = await this.$refs.ReferenceSelector.select($refId);

                        if ($refData) {

                            $($event.target).addClass('p-button-info').removeClass('p-button-danger').data('refValue', $refData).addClass('xData');

                            $($event.target).siblings('section').find('[data-xsd2html2xml-namewithoutns="СсылкаСправочника"] [data-xsd2html2xml-namewithoutns="ИД"]:first input:first').val($refData.id);
                            $($event.target).siblings('section').find('[data-xsd2html2xml-namewithoutns="СсылкаСправочника"] [data-xsd2html2xml-namewithoutns="Название"]:first input:first').val($refData.title);

                            $($event.target).text(($refData.title ?? $refData.id));

                        }

                    });


                });
            }

            function AddButtonsSelectProcess() {

                let _event_info = $($iframe.contentDocument).find('[data-xsd2html2xml-namewithoutns="ИнформацияОСобытии"]');

                _event_info.each((index, item) => {

                    let _event_status = $(item).find('[data-xsd2html2xml-namewithoutns="СтатусСобытия"]:first select:first').val();

                    if (!_event_status)
                        return;

                    if ($(item).parents('[hidden]')?.length)
                        return;

                    let _proc_info = $(item).parents('fieldset:first').find('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"]:first')

                    if (!_proc_info)
                        return;

                    if (_event_status !== 'Инициация') {

                        if (!$(_proc_info).children('.selectProcess').length) {

                            let elemDiv = document.createElement('button');
                            elemDiv.innerText = 'Выбрать процесс';
                            elemDiv.classList.value = 'p-button p-button-danger p-button-sm p-button-outlined p-component selectProcess mt-3 mb-3';
                            $(elemDiv).css('margin-top', '5px');

                            $(_proc_info).append(elemDiv);

                            let _role = $(item).parent().parent().find('section > [data-xsd2html2xml-namewithoutns="АгентыСобытия"] > section > [data-xsd2html2xml-namewithoutns="АгентОтправитель"] > section > fieldset');

                            let $role_id = $(_role).find('[data-xsd2html2xml-namewithoutns="ВидИД"] input').val();
                            let $role_name = $(_role).find('[data-xsd2html2xml-namewithoutns="ВидНазвание"] input').val();


                            $(elemDiv).data('role_id', $role_id).addClass('xData');
                            $(elemDiv).data('role_name', $role_name).addClass('xData');

                            // Проверка на то, что поля процесса заполнены. Если заполнены, то меняем стиль кнопки выбора процесса и текст кнопки
                            let v1 = $(_proc_info).find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]:first').find('[data-xsd2html2xml-namewithoutns="ПроцессУУИД"] input').val()
                            let v2 = $(_proc_info).find('[data-xsd2html2xml-xpath-without-ns="' + $(_proc_info).attr('data-xsd2html2xml-xpath-without-ns') + '/@ВидНазвание' + '"]').find('input, select, textarea').val()

                            if (v1 && v2) {

                                $(elemDiv).text($(_proc_info).find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]:first').find('[data-xsd2html2xml-namewithoutns="Представление"] input').val());
                                $(elemDiv).addClass('p-button-info').removeClass('p-button-danger')

                                $(elemDiv)
                                    .parent('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"]')
                                    .parents('fieldset:first')
                                    .find('[data-xsd2html2xml-namewithoutns="АгентыСобытия"]')
                                    .find('[data-xsd2html2xml-namewithoutns="АгентыПолучатели"]')
                                    .find('.selectAbonentBtn')
                                    .removeClass('disabled')
                                    .prop('disabled', false);

                            }


                            const selectProcessBtnHandler = async ($event) => {

                                $event.stopImmediatePropagation();
                                $event.preventDefault();
                                $event.stopPropagation();

                                const $procKind = $($event.target).siblings('[data-xsd2html2xml-namewithoutns="ВидИД"]').find('input').val();

                                const $eventBlockElement = $event.target.closest('.eventBlock')

                                const $roleIdEl = $eventBlockElement.querySelector('[data-xsd2html2xml-namewithoutns="АгентыСобытия"] [data-xsd2html2xml-namewithoutns="АгентОтправитель"] [data-xsd2html2xml-namewithoutns="ВидИД"] input')
                                if (!$roleIdEl)
                                    return;

                                const roleId = $roleIdEl.value

                                let $processSelectorData = await this.$refs.ProcessSelector.select(
                                    {
                                        custom_filters: {
                                            is_finished: false,
                                            docs_guids: $context?.linked_docs?.map(item => item.guid),
                                            process_type_id: $procKind,
                                            session_id: $context?.session_id,
                                            roles: {
                                                agentRoleId: roleId,
                                                agentGuid: this.sourceOrgGuid
                                            }
                                        },
                                        selectFirstIfOnlyOne: $event?.type === 'xclick'

                                    });

                                if (!$processSelectorData)
                                    return;

                                let $processData = $processSelectorData.process;

                                let $allowedByRoleInSelectedProcess = $processData.roles.some(item => {
                                    return ((item.agentGuid === this.sourceOrgGuid) && (item.agentRoleId === $($event.target).data('role_id')))
                                });

                                if (!$allowedByRoleInSelectedProcess) {
                                    this.$xapi.xShowModalError({text: 'У вас неподходящая роль в выбранном процессе'});
                                    return;
                                }

                                if ($processData) {

                                    const clickedButton = $event.target

                                    $(clickedButton).addClass('p-button-info').removeClass('p-button-danger')
                                    $(clickedButton).parent().data('process', $processData).addClass('xData');

                                    $($eventBlockElement).find('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"] > section > [data-xsd2html2xml-namewithoutns="ВремяСоздания"] > input')
                                        .val(DataFilters.methods.unixTimeToISO($processData.time_created)).trigger('change');

                                    $($eventBlockElement).find('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"] > section > [data-xsd2html2xml-namewithoutns="СсылкаПроцесса"] [data-xsd2html2xml-namewithoutns="ПроцессУУИД"] > input')
                                        .val($processData.guid).attr('value', $processData.guid);

                                    $($eventBlockElement).find('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"] > section > [data-xsd2html2xml-namewithoutns="СсылкаСоздателя"] [data-xsd2html2xml-namewithoutns="АгентУУИД"]:first > input:first')
                                        .val($processData.creator_guid).attr('value', $processData.creator_guid);

                                    $($eventBlockElement).find('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"] > section > [data-xsd2html2xml-namewithoutns="СсылкаСоздателя"] [data-xsd2html2xml-namewithoutns="Представление"]:first > input:first')
                                        .val($processData.creator_title).attr('value', $processData.creator_title);


                                    ($eventBlockElement.ownerDocument?.defaultView || $eventBlockElement.ownerDocument?.parentWindow)._fillCalculatedFields();

                                    $(clickedButton).text($(clickedButton).parent().find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]:first').find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first').val());

                                    // Данные для визуализации
                                    // Тут надо рекурсивно вставлять в данные визуализации данные о координации с возможностью редактирования для пользователя

                                    let $eventThings = $(clickedButton).parent().parent().parent().find('[data-xsd2html2xml-namewithoutns="ПредметыСобытия"] > section > fieldset').not('[hidden]');
                                    let $dataForCoordination = $processData.data_for_coordination;

                                    // Удаление всех опциональных полей в предметах, что бы не были открыты лишние
                                    $eventThings.each(($k, $thing) => {

                                        let $visualizationData = $($thing).find('[data-xsd2html2xml-namewithoutns="ДанныеДляВизуализации"]:first');
                                        $($visualizationData).find('button.remove').not('[hidden]').each((index, item) => {

                                            if (!$(item).parents('[hidden]'))
                                                $(item).trigger('click');

                                        });

                                    });

                                    // Заполнение данных для визуализации из данных по координации
                                    $eventThings.each(($k, $thing) => {

                                        const thingName = $($thing).attr('data-xsd2html2xml-namewithoutns');

                                        const thingPath = $($thing).attr('data-xsd2html2xml-xpath-without-ns');

                                        let $visualizationData = $($thing).find('[data-xsd2html2xml-namewithoutns="ДанныеДляВизуализации"]:first');

                                        if ($dataForCoordination) {

                                            for (const prop in $dataForCoordination?.[thingName]) {

                                                let $element = $($visualizationData).find('[data-xsd2html2xml-xpath-without-ns="' + thingPath + '/ДанныеДляВизуализации' + prop + '"]').find('input, select, textarea');

                                                if (!$element)
                                                    continue;

                                                $($element).parents('[hidden]').next('button.add').not('[hidden]').trigger('click');

                                                $element = $($visualizationData).find('[data-xsd2html2xml-xpath-without-ns="' + thingPath + '/ДанныеДляВизуализации' + prop + '"]').not('[hidden]').find('input, select, textarea');

                                                $element = $($element).toArray().find(item => {
                                                    return ($(item).parents('[hidden]').length === 0) && (!$($element).attr('hidden'))
                                                })

                                                if ($element) {
                                                    $($element).val($dataForCoordination?.[thingName]?.[prop]?.[0].value).trigger('change');
                                                }

                                            }

                                        }

                                    });

                                    // Автозаполнение получаетелй

                                    $($eventBlockElement).find('[data-xsd2html2xml-namewithoutns="АгентыСобытия"]:first [data-xsd2html2xml-namewithoutns="АгентыПолучатели"]:first button.remove').each((index, item) => {

                                        if (!item.hasAttribute('hidden') && !item.closest('[hidden]'))
                                            $(item).trigger('click')

                                    });

                                    $processData?.roles.forEach(role => {

                                        let $_input_role_id = $($eventBlockElement).find('[data-xsd2html2xml-namewithoutns="АгентыСобытия"]:first [data-xsd2html2xml-namewithoutns="АгентыПолучатели"]:first [value="' + role.agentRoleId + '"]')

                                        $($_input_role_id).parent().parent().siblings('button.add').trigger('click');

                                        $_input_role_id = $($eventBlockElement).find('[data-xsd2html2xml-namewithoutns="АгентыСобытия"]:first [data-xsd2html2xml-namewithoutns="АгентыПолучатели"]:first [value="' + role.agentRoleId + '"]')

                                        let $_agentLink

                                        $($_input_role_id).each(($index, $input_element) => {

                                            if (!$input_element.hasAttribute('hidden') && !$input_element.closest('[hidden]') && !$($input_element).parent().parent().find('[data-xsd2html2xml-namewithoutns="АгентУУИД"] input').val()) {

                                                $_agentLink = $($input_element).parent().parent().find('[data-xsd2html2xml-element-type-without-ns="ТипСсылкаАгента"]');
                                                return false;
                                            }

                                        });

                                        if ($_agentLink) {

                                            $($_agentLink).find('[data-xsd2html2xml-namewithoutns="АгентУУИД"] input').not('[hidden]').first().val(role?.agentGuid).trigger('change')
                                            $($_agentLink).find('[data-xsd2html2xml-namewithoutns="Представление"] input').not('[hidden]').first().val(role?.agentTitle).trigger('change')

                                        }

                                    })

                                    $(clickedButton)
                                        .parent('[data-xsd2html2xml-namewithoutns="ИнформацияОПроцессе"]')
                                        .parents('fieldset:first')
                                        .find('[data-xsd2html2xml-namewithoutns="АгентыСобытия"]')
                                        .find('[data-xsd2html2xml-namewithoutns="АгентыПолучатели"]')
                                        .find('.selectAbonentBtn')
                                        .removeClass('disabled')
                                        .prop('disabled', false);


                                    if ($processSelectorData?.parentProcess) {

                                        let _parentProcessHidden = $(clickedButton).parent().find('[data-xsd2html2xml-namewithoutns="ИнформацияОВышестоящем"]');

                                        $(_parentProcessHidden).next('button.add').trigger('click');

                                        let _parentProcess = $(_parentProcessHidden).siblings('[data-xsd2html2xml-namewithoutns="ИнформацияОВышестоящем"]').not('[hidden]').first();

                                        $(_parentProcess).prop('disabled', true).addClass('disabled').addClass('serviceInfo').css('display', 'none');
                                        $(_parentProcess).find('button.remove').hide();

                                        //this.switchServiceInfo(this.showServiceInfo);

                                        const parentProcUUID = $(_parentProcess).find('[data-xsd2html2xml-namewithoutns="ПроцессУУИД"]:first input')
                                        $(parentProcUUID).val($processSelectorData.parentProcess.guid);
                                        parentProcUUID[0].value = $processSelectorData.parentProcess.guid

                                        $(_parentProcess).find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first').val($processSelectorData.parentProcess.process_view);


                                        $(_parentProcess).find('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]:first [data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first').val($processSelectorData.parentProcess.creator_guid);
                                        $(_parentProcess).find('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]:first [data-xsd2html2xml-namewithoutns="Представление"]:first input:first').val($processSelectorData.parentProcess.creator_title);

                                        $(_parentProcess).find('[data-xsd2html2xml-namewithoutns="ВремяСоздания"]:first input:first').val(DataFilters.methods.unixTimeToISO($processSelectorData.parentProcess.time_created)).trigger('change');

                                        $(_parentProcess).find('[data-xsd2html2xml-namewithoutns="ВидИД"]:first input:first').val($processSelectorData.parentProcess.process_type_id);
                                        $(_parentProcess).find('[data-xsd2html2xml-namewithoutns="ВидНазвание"]:first input:first').val($processSelectorData.parentProcess.process_type_name);

                                    }

                                    this.addButtons($iframe, $context)

                                }


                            };

                            // Создание event handler для клика на кнопку выбора процесса
                            elemDiv.removeEventListener('click', selectProcessBtnHandler)
                            elemDiv.removeEventListener('xclick', selectProcessBtnHandler)
                            elemDiv.addEventListener('click', selectProcessBtnHandler)
                            elemDiv.addEventListener('xclick', selectProcessBtnHandler)

                            const ev = new CustomEvent("xclick");
                            elemDiv.dispatchEvent(ev)

                        }

                    }

                    if (_event_status === 'Инициация') {

                        let _parentProcessInfos = $(_proc_info).children('section').children('[data-xsd2html2xml-namewithoutns="ИнформацияОВышестоящем"]').not('[hidden]');


                        $(_parentProcessInfos).each((idx, _parentProcessInfo) => {


                            if (!$(_parentProcessInfo).children('.selectParentProcess').length) {

                                let elemDiv = document.createElement('button');
                                elemDiv.innerText = 'Выбрать вышестоящий процесс';
                                elemDiv.classList.value = 'p-button p-button-danger p-button-sm p-button-outlined p-component selectParentProcess mt-3 mb-3';
                                $(elemDiv).css('margin-top', '5px');

                                $(_parentProcessInfo).append(elemDiv);

                                $(_parentProcessInfo).children('section').find('[data-xsd2html2xml-namewithoutns="ВремяСоздания"]:first input:first').prop('disabled', true).addClass('disabled');
                                $(_parentProcessInfo).find('[data-xsd2html2xml-namewithoutns="ВидИД"]:first input:first').prop('disabled', true).addClass('disabled');
                                $(_parentProcessInfo).find('[data-xsd2html2xml-namewithoutns="ВидНазвание"]:first input:first').prop('disabled', true).addClass('disabled');


                                $(_parentProcessInfo).children('section').find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').find('[data-xsd2html2xml-namewithoutns="ПроцессУУИД"] input').prop('disabled', true).addClass('disabled');
                                $(_parentProcessInfo).children('section').find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first').prop('disabled', true).addClass('disabled');
                                $(_parentProcessInfo).children('section').find('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]').find('[data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first').prop('disabled', true).addClass('disabled');
                                $(_parentProcessInfo).children('section').find('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]').find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first').prop('disabled', true).addClass('disabled');

                                // Проверка на то, что поля абонента заполнены. Если заполнены, то меняем стиль кнопки выбора абонента и текст кнопки
                                let v1 = $(_parentProcessInfo).find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').find('[data-xsd2html2xml-namewithoutns="Представление"]').find('input, select, textarea').val()
                                let v2 = $(_parentProcessInfo).find('[data-xsd2html2xml-namewithoutns="ВидНазвание"]').find('input, select, textarea').val()
                                if (v1 && v2) {
                                    $(elemDiv).text('Вышестоящий процесс: ' + v1 + ' (' + v1 + ')');
                                    $(elemDiv).addClass('p-button-info').removeClass('p-button-danger').removeAttr('disabled')
                                }

                                $($iframe.contentDocument.body).on('click', '.selectParentProcess', async ($event) => {

                                    $event.stopImmediatePropagation();
                                    $event.preventDefault();
                                    $event.stopPropagation();

                                    let $processSelectorData = await this.$refs.ProcessSelector.select({
                                        filters: {is_finished: false},
                                        showFinishedColumn: true
                                    });

                                    let $processData = $processSelectorData.process;

                                    if ($processData) {

                                        $($event.target).addClass('p-button-info').removeClass('p-button-danger').data('process', $processData).addClass('xData');

                                        $($event.target).parent().children('section').find('[data-xsd2html2xml-namewithoutns="ВремяСоздания"]:first input:first').val(DataFilters.methods.unixTimeToISO($processData.time_created)).trigger('change');
                                        $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="ВидИД"] input').val($processData.process_type_id);
                                        $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="ВидНазвание"] input').val($processData.process_type_name).attr('value', $processData.process_type_name);


                                        $($event.target).parent().children('section').find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').find('[data-xsd2html2xml-namewithoutns="ПроцессУУИД"] input').val($processData.guid).attr('value', $processData.guid).trigger('change');
                                        //$($event.target).parent().children('section').find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').find('[data-xsd2html2xml-description="Представление"]').val($processData.process_view);
                                        $($event.target).parent().children('section').find('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]').find('[data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first').val(this.sourceOrgGuid);
                                        $($event.target).parent().children('section').find('[data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]').find('[data-xsd2html2xml-namewithoutns-description="Представление"]:first input:first').val(this.sourceOrgTitle);

                                        $iframe.contentWindow._fillCalculatedFields();

                                        $($event.target).text('Вышестоящий процесс: ' + $($event.target).parent().children('section').find('[data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first').val());


                                    }

                                });

                            }

                        });

                    }

                    $(item).parents('fieldset:first').find('[data-xsd2html2xml-namewithoutns="ИнформацияОВышестоящем"] [data-xsd2html2xml-namewithoutns="СсылкаПроцесса"]').addClass('serviceInfo');
                    $(item).parents('fieldset:first').find('[data-xsd2html2xml-namewithoutns="ИнформацияОВышестоящем"] [data-xsd2html2xml-namewithoutns="СсылкаСоздателя"]').addClass('serviceInfo');
                    $(item).parents('fieldset:first').find('[data-xsd2html2xml-namewithoutns="ИнформацияОВышестоящем"] [data-xsd2html2xml-namewithoutns="ВремяСоздания"]').addClass('serviceInfo');


                });
            }

            function AddButtonsSelectDocument() {

                let $_documents = $iframe.contentDocument.body.querySelectorAll('[data-xsd2html2xml-namewithoutns="СсылкаДокумента"]');

                $($_documents).not('[hidden]').each((idx, $_document) => {


                    if ($($_document).parents('[data-xsd2html2xml-namewithoutns="ЗаголовокДокумента"]').length)
                        return;

                    if (
                        !$($_document).find('[data-xsd2html2xml-namewithoutns="ДокументУУИД"]').length ||
                        !$($_document).find('[data-xsd2html2xml-namewithoutns="Представление"]').length ||
                        !$($_document).parent().siblings('[data-xsd2html2xml-namewithoutns="ВидИД"]').length ||
                        !$($_document).parent().siblings('[data-xsd2html2xml-namewithoutns="ВидНазвание"]').length
                    ) {
                        return;
                    }


                    $($_document).addClass('serviceInfo');
                    $($_document).find('[data-xsd2html2xml-namewithoutns="ДокументУУИД"]:first input:first').prop('disabled', true).addClass('disabled');
                    $($_document).find('[data-xsd2html2xml-namewithoutns="Представление"]:first input:first').prop('disabled', true).addClass('disabled');
                    $($_document).parent().siblings('[data-xsd2html2xml-namewithoutns="ВидИД"]').prop('disabled', true).addClass('disabled').addClass('serviceInfo');
                    $($_document).parent().siblings('[data-xsd2html2xml-namewithoutns="ВидНазвание"]').prop('disabled', true).addClass('disabled').addClass('serviceInfo');

                    // Удаление кнопки если она уже есть. Для того, что бы если поля заполнены, то кнопка создалась заполненной тоже
                    $($_document).parent().parent().children('.selectFromDocument').remove()

                    // Создание самой кнопки
                    let elemDiv = document.createElement('button');
                    elemDiv.innerText = 'Выбрать документ';
                    elemDiv.classList.value = 'p-button p-button-danger p-button-sm p-button-outlined p-component selectFromDocument mt-3 mb-3';
                    $(elemDiv).css('margin-top', '5px');

                    $($_document).parent().parent().append(elemDiv);

                    let v1 = $($_document).find('[data-xsd2html2xml-namewithoutns="ДокументУУИД"]').find('input, select, textarea').val()
                    let v2 = $($_document).find('[data-xsd2html2xml-namewithoutns="Представление"]').find('input, select, textarea').val()
                    if (v1 && v2) {
                        $(elemDiv).text(v2 ?? v1);
                        $(elemDiv).addClass('p-button-info').removeClass('p-button-danger').removeAttr('disabled')
                    }

                    $($iframe.contentDocument.body).on('click', '.selectFromDocument', async ($event) => {

                        $event.stopImmediatePropagation();
                        $event.preventDefault();
                        $event.stopPropagation();

                        let $docTypeId = $($event.target).siblings('[data-xsd2html2xml-namewithoutns="ВидНазвание"]').find('[data-xsd2html2xml-namewithoutns="ВидНазвание"]:first input:first').val();

                        let $docData = await this.$refs.DocumentSelector.select({
                            type_title: $docTypeId,
                        });

                        if ($docData) {

                            $($event.target).addClass('p-button-info').removeClass('p-button-danger').data('refValue', $docData).addClass('xData');

                            $($event.target).siblings('section').find('[data-xsd2html2xml-namewithoutns="СсылкаДокумента"] [data-xsd2html2xml-namewithoutns="ДокументУУИД"]:first input:first').val($docData.guid);
                            $($event.target).siblings('section').find('[data-xsd2html2xml-namewithoutns="СсылкаДокумента"] [data-xsd2html2xml-namewithoutns="Представление"]:first input:first').val($docData.title);

                            $($event.target).text(($docData.title ?? $docData.guid));

                        }

                    });


                });

            }

            function AddButtonsSelectLinkedDoc() {

                let $_documents = $iframe.contentDocument.body.querySelectorAll('[data-xsd2html2xml-element-type-without-ns="ТипСсылкаДокумента"],[data-xsd2html2xml-element-type-without-ns="ТипРеквизитыДокумента"]');

                $($_documents).not('[hidden]').each((idx, $_document) => {

                    //$($_document).addClass('serviceInfo');
                    $($_document).find('[data-xsd2html2xml-namewithoutns="ДокументУУИД"]:first').addClass('serviceInfo').find('input:first').prop('disabled', true).addClass('disabled');
                    $($_document).find('[data-xsd2html2xml-namewithoutns="Представление"]:first').addClass('serviceInfo').find('input:first').prop('disabled', true).addClass('disabled');

                    $($_document).find('[data-xsd2html2xml-namewithoutns="РегистрацияДокумента"]').addClass('serviceInfo');

                    $($_document).find('[data-xsd2html2xml-namewithoutns="РегистрацияДокумента"] [data-xsd2html2xml-namewithoutns="ОрганРегистрации"] [data-xsd2html2xml-namewithoutns="АгентУУИД"]').addClass('serviceInfo').find('input:first').prop('disabled', true).addClass('disabled');
                    $($_document).find('[data-xsd2html2xml-namewithoutns="РегистрацияДокумента"] [data-xsd2html2xml-namewithoutns="ОрганРегистрации"] [data-xsd2html2xml-namewithoutns="Представление"]').addClass('serviceInfo').find('input:first').prop('disabled', true).addClass('disabled');

                    $($_document).find('[data-xsd2html2xml-namewithoutns="РегистрацияДокумента"] [data-xsd2html2xml-namewithoutns="НомерРегистрации"]').addClass('serviceInfo').find('input:first').prop('disabled', true).addClass('disabled');
                    $($_document).find('[data-xsd2html2xml-namewithoutns="РегистрацияДокумента"] [data-xsd2html2xml-namewithoutns="ДатаРегистрации"]').addClass('serviceInfo').find('input:first').prop('disabled', true).addClass('disabled');

                    // Удаление кнопки если она уже есть. Для того, что бы если поля заполнены, то кнопка создалась заполненной тоже
                    $($_document).children('.selectFromLinkedDocument').remove()

                    // Создание самой кнопки
                    let elemDiv = document.createElement('button');
                    elemDiv.innerText = 'Выбрать документ';
                    elemDiv.classList.value = 'p-button p-button-danger p-button-sm p-button-outlined p-component selectFromLinkedDocument mt-3 mb-3';
                    $(elemDiv).css('margin-top', '5px');

                    $($_document).append(elemDiv);

                    let v1 = $($_document).find('[data-xsd2html2xml-namewithoutns="ДокументУУИД"]').find('input, select, textarea').first().val()
                    let v2 = $($_document).find('[data-xsd2html2xml-namewithoutns="Представление"]').find('input, select, textarea').first().val()
                    if (v1 && v2) {
                        $(elemDiv).text(v2 ?? v1);
                        $(elemDiv).addClass('p-button-info').removeClass('p-button-danger').removeAttr('disabled')
                    }

                    $($iframe.contentDocument.body).on('click', '.selectFromLinkedDocument', async ($event) => {

                        $event.stopImmediatePropagation();
                        $event.preventDefault();
                        $event.stopPropagation();

                        let custom_filter = {}

                        if ($context?.linked_docs)
                            custom_filter.guid = $context?.linked_docs?.map(item => item.guid)


                        if ($($event.target).parent().attr('data-xsd2html2xml-element-type-without-ns') === 'ТипРеквизитыДокумента') {
                            custom_filter = {
                                has_reg_data: true
                            }
                        }

                        let $docData = await this.$refs.DocumentSelector.select(null, custom_filter);

                        if ($docData) {

                            $($event.target).addClass('p-button-info').removeClass('p-button-danger').addClass('xData');

                            $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="ДокументУУИД"] input:first').val($docData.guid);
                            $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="Представление"] input:first').val($docData.title);

                            $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="РегистрацияДокумента"]:first [data-xsd2html2xml-namewithoutns="ОрганРегистрации"]:first [data-xsd2html2xml-namewithoutns="АгентУУИД"]:first input:first').val($docData?.reg_org_guid);
                            $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="РегистрацияДокумента"]:first [data-xsd2html2xml-namewithoutns="ОрганРегистрации"]:first [data-xsd2html2xml-namewithoutns="Представление"]:first input:first').val($docData?.reg_org_title);

                            $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="РегистрацияДокумента"]:first [data-xsd2html2xml-namewithoutns="НомерРегистрации"]:first input:first').val($docData?.reg_num);
                            $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="РегистрацияДокумента"]:first [data-xsd2html2xml-namewithoutns="ДатаРегистрации"]:first input:first').val(DataFilters.methods.dateToYMD($docData?.reg_date * 1000)).trigger('change');

                            $($event.target).text(($docData.title ?? $docData.guid));

                        }

                    });

                });

            }

            function AddButtonsSelectUser() {

                if (!$context?.users)
                    return;

                let $_users = $iframe.contentDocument.body.querySelectorAll('[data-xsd2html2xml-element-type-without-ns="ТипДолжностноеЛицо"], [data-xsd2html2xml-element-type-without-ns="ТипФизическоеЛицо"]');

                $($_users).not('[hidden]').each((idx, $_user) => {

                    $($_user).children('[data-xsd2html2xml-namewithoutns="ФИО"]').addClass('serviceInfo').find('input:first').prop('disabled', true).addClass('disabled');
                    $($_user).children('[data-xsd2html2xml-namewithoutns="ИД"]').addClass('serviceInfo').find('input:first').prop('disabled', true).addClass('disabled');

                    // Удаление кнопки если она уже есть. Для того, что бы если поля заполнены, то кнопка создалась заполненной тоже
                    $($_user).children('.selectFromUsers').remove()

                    // Создание самой кнопки
                    let elemDiv = document.createElement('button');
                    elemDiv.innerText = 'Выбрать сотрудника';
                    elemDiv.classList.value = 'p-button p-button-danger p-button-sm p-button-outlined p-component selectFromUsers mt-3 mb-3';
                    $(elemDiv).css('margin-top', '5px');

                    $($_user).append(elemDiv);

                    let v1 = $($_user).find('[data-xsd2html2xml-namewithoutns="ФИО"]').find('input, select, textarea').first().val()
                    let v2 = $($_user).find('[data-xsd2html2xml-namewithoutns="ИД"]').find('input, select, textarea').first().val()
                    if (v1 && v2) {
                        $(elemDiv).text(v1);
                        $(elemDiv).addClass('p-button-info').removeClass('p-button-danger').removeAttr('disabled')
                    }

                    $($iframe.contentDocument.body).on('click', '.selectFromUsers', async ($event) => {

                        $event.stopImmediatePropagation();
                        $event.preventDefault();
                        $event.stopPropagation();

                        let $userData = await this.$refs.UserSelector.select($context?.users);

                        if ($userData) {

                            $($event.target).addClass('p-button-info').removeClass('p-button-danger').addClass('xData');

                            $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="ФИО"] input:first').val($userData.fio);
                            $($event.target).parent().find('[data-xsd2html2xml-namewithoutns="ИД"] input:first').val($userData.id);

                            $($event.target).text(($userData.fio));

                        }

                    });

                });

            }

            AddButtonsSelectProcess.call(this)
            AddButtonsSelectAgentsReceivers.call(this)
            AddButtonsSelectAgent.call(this)
            AddButtonsSelectReference.call(this)
            AddButtonsSelectDocument.call(this)
            AddButtonsSelectLinkedDoc.call(this)
            AddButtonsSelectUser.call(this)

        }

    },
}
</script>

<style scoped>

</style>