

import {DataFilters, XModal} from "@xnpmpackages/xcomponents/dist/index"
import {FilterMatchMode} from "primevue/api";

export default {
    name: "UserSelector",
    mixins: [DataFilters],
    data() {
        return {
            promiseResolver: null,
            tableItems: null,
            filters: {
                global: {value: null, matchMode: FilterMatchMode.CONTAINS},
                fio: {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
        }
    },
    methods: {

        async tableItemsClick($event) {

            if (this.promiseResolver)
                this.promiseResolver($event.data);

            this.$refs.XModal.hide();

        },

        async select(users) {

            let P = new Promise(resolve => this.promiseResolver = resolve);

            await this.$refs.XModal.show();

            this.tableItems = users

            return P;

        }

    },
    components: {
        XModal,
    }
}
